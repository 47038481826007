.tc_content {
  flex: 1 1 auto;

  &.tc_padding {
    padding: calc(var(--MarginY) * 2px) calc(var(--MarginX) * 2px);
  }

  &.tc_edit {
    border: 1px solid var(--ColorMap-ContentFrame1ForChange);
  }

  &.tc_read {
    border: 1px solid var(--ColorMap-ContentFrame1);
  }

  &.tc_no_left_border {
    border-left: none;
  }

  &.tc_no_top_border {
    border-top: none;
  }

  @media (max-width: 980px) {
    &.tc_no_border {
      border: none;
    }
  }
}
