@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes box-1 {
  0% {
    left: -35%;
    right: 100%;
  }
  60%,
  100% {
    left: 100%;
    right: -90%;
  }
}
@keyframes box-2 {
  0% {
    left: -200%;
    right: 100%;
  }
  60%,
  100% {
    left: 107%;
    right: -8%;
  }
}

.bi {
  z-index: -1;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;

  &[data-background="1"] {
    background: rgba(0, 0, 0, 0.35);
    .bi_title,
    .bi_message {
      color: #fff;
    }
  }

  &[data-visibility="1"] {
    z-index: 9999;
    opacity: 1;

    .bi_wrap {
      transform: scale(1);
    }

    .bi_wrap .bi_horizontal_loader {
      height: 5px;
    }
  }

  //LeftTop
  &[data-align="0"] {
    justify-content: flex-start;
    align-items: flex-start;
  }
  //LeftMiddle
  &[data-align="1"] {
    justify-content: flex-start;
    align-items: center;
  }
  //LeftBottom
  &[data-align="2"] {
    justify-content: flex-start;
    align-items: flex-end;
  }
  //CenterTop
  &[data-align="3"] {
    justify-content: center;
    align-items: flex-start;
  }
  //Center
  &[data-align="4"] {
    justify-content: center;
    align-items: center;
  }
  //CenterBottom
  &[data-align="5"] {
    justify-content: center;
    align-items: flex-end;
  }
  //RightTop
  &[data-align="6"] {
    justify-content: flex-end;
    align-items: flex-start;
  }
  //RightMiddle
  &[data-align="7"] {
    justify-content: flex-end;
    align-items: center;
  }
  //RightBottom
  &[data-align="8"] {
    justify-content: flex-end;
    align-items: flex-end;
  }

  .bi_wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: scale(0.5);
    transition: transform 0.2s ease;
    padding: 10px;
    min-width: 300px;
    height: auto;
    width: auto;

    &.bi_modal {
      background-color: #ffffffee;
      box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.6);
      & .bi_title,
      & .bi_message {
        color: #000;
      }
    }
    .bi_title {
      font-size: 18px;
      padding-bottom: 5px;
    }
    .bi_message {
      font-size: 14px;
      padding-bottom: 5px;
    }
    .loginBtn,
    .stopBtn {
      margin-top: 5px;
    }
    .stopBtn {
      background: #ccc;
      color: #000;
      width: 70%;
      height: 32px;
      border: none;
    }

    .bi_loader {
      min-width: 86px;
      min-height: 86px;
      position: relative;
      margin: 15px;
      display: flex;
      justify-content: center;
      align-items: center;

      .bi_children {
        z-index: 1;
      }

      & .bi_children {
        padding: 25px;
        color: #0054a6;
        & .bi_progress {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          overflow: hidden;
        }
      }

      .bi_loading {
        width: 100%;
        height: 100%;
        color: #f0f0f0;
        position: absolute;
        display: inline-block;
        border: 6px solid;
        border-radius: 50%;
        border-right-color: #0054a6;
        animation: rotate 1s linear infinite;
      }
    }

    .bi_horizontal_loader {
      position: absolute;
      transition: all 0.3s ease;
      z-index: 99999;
      top: 0;
      left: 0;
      width: 100%;
      height: 0;
      background: #f0f0f0;
      overflow: hidden;

      &::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        bottom: 0px;
        background: #0054a6;
        animation: box-1 2100ms cubic-bezier(0.65, 0.81, 0.73, 0.4) infinite;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        bottom: 0px;
        background: #0054a6;
        animation: box-2 2100ms cubic-bezier(0.16, 0.84, 0.44, 1) infinite;
        animation-delay: 1150ms;
      }
    }
    @media (max-width: 350px) {
      min-width: inherit;
      padding: 15px;
    }
    &.bi_finished {
      .bi_loader .bi_loading {
        color: #0054a6;
      }
    }
  }
}
.sbi {
  position: absolute;
  padding: 10px;
  transition: opacity 200ms ease-in-out;
  z-index: -1;
  opacity: 0;
  $sbiSpace: 5px;

  &[data-visibility="1"] {
    z-index: 9999;
    opacity: 1;
  }
  &[data-align="0"] {
    top: $sbiSpace;
    left: $sbiSpace;
  }
  //LeftMiddle
  &[data-align="1"] {
    top: 50%;
    transform: translateY(-50%);
    left: $sbiSpace;
  }
  //LeftBottom
  &[data-align="2"] {
    bottom: $sbiSpace;
    left: $sbiSpace;
  }
  //CenterTop
  &[data-align="3"] {
    top: $sbiSpace;
    left: 50%;
    transform: translateX(-50%);
  }
  //Center
  &[data-align="4"] {
    top: 50%;
    transform: translateY(-50%);
    left: 50%;
    transform: translateX(-50%);
  }
  //CenterBottom
  &[data-align="5"] {
    bottom: $sbiSpace;
    left: 50%;
    transform: translateX(-50%);
  }
  //RightTop
  &[data-align="6"] {
    top: $sbiSpace;
    right: $sbiSpace;
  }
  //RightMiddle
  &[data-align="7"] {
    top: 50%;
    transform: translateY(-50%);
    right: $sbiSpace;
  }
  //RightBottom
  &[data-align="8"] {
    bottom: $sbiSpace;
    right: $sbiSpace;
  }

  .sbi_content {
    background-color: #00539b;
    border-radius: 5px;
    padding: 4px;
  }
}

.bi_global {
  .sbi,
  .bi {
    position: fixed;
  }
}
