@use "./../VCX/base";

$size: 16px;

.rb {
  @include base.label_font;
  padding: 3px;
  color: var(--ColorMap-BaseColorBck1);

  &.rb_framed {
    border: 1px solid var(--ColorMap-ContentFrame1);
  }

  &.rb_ha_center {
    .rb_group {
      margin: auto;
    }
  }

  &.rb_ha_right {
    .rb_label {
      flex-direction: row-reverse;
    }
  }

  &.rb_va_top {
    align-items: flex-start;
  }

  &.rb_va_center {
    align-items: center;
  }

  &.rb_va_bottom {
    align-items: flex-end;
  }

  .rb_form {
    display: grid;
    flex: 1 1 auto;

    .rb_label {
      display: flex;
      gap: 6px;
      padding-bottom: 3px;

      &:last-child {
        padding-bottom: 0px;
      }

      &:focus-within {
        .rb_group {
          border: 1px dotted var(--ColorMap-BaseColorBck1);
        }
      }

      &.rb_cancelled {
        .rb_title {
          opacity: 0.6;
        }
      }

      .rb_input {
        appearance: none;
        display: grid;
        place-content: center;
        border: 1px solid var(--ColorMap-BaseColorBck1);
        height: calc(var(--Zoom) * $size);
        width: calc(var(--Zoom) * $size);
        border-radius: 50%;
        background-color: var(--ColorMap-BaseColorFrg1);
        flex: 0 0 auto;

        &:hover {
          outline: 2px solid var(--ColorMap-BaseColorBck1);
        }

        &:checked::before {
          content: "";
          display: block;
          width: calc(var(--Zoom) * calc($size / 2));
          height: calc(var(--Zoom) * calc($size / 2));
          border-radius: 50%;
          background-color: var(--ColorMap-BaseColorBck1);
        }
      }

      .rb_group {
        display: flex;
        gap: 6px;
        border: 1px dotted transparent;

        .rb_title {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.rb_edit {
  &.framed {
    border: 1px solid var(--ColorMap-ContentFrame1ForChange);
  }

  .rb_form .rb_label .rb_input {
    background-color: var(--ColorMap-DataChangeColorBck);
  }
}
