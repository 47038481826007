.img {
  display: flex;
  justify-content: center;

  .svg {
    display: flex;
    flex: 1 1 auto;
    position: relative;

    & > svg {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  .non_svg {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    -o-object-fit: contain;
  }
  &.qr {
    .non_svg {
      image-rendering: pixelated;
    }
  }
}
