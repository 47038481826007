.od {
  display: flex;
  flex-direction: column;
  padding: calc(var(--Zoom) * 6px);
  background-color: #fff;

  .od_body {
    display: flex;
    flex-direction: column;
    gap: calc(var(--Zoom) * 10px);

    .od_drop_area {
      display: flex;
      flex-direction: column;
      min-height: 15vh;
      border: calc(var(--Zoom) * 2px) dashed var(--ColorMap-ContentFrame1);
      justify-content: center;
      align-items: center;

      &.od_drop_area_dragging {
        border: calc(var(--Zoom) * 2px) dashed #000;
      }

      .od_text {
        font-size: 130%;
        line-height: 140%;
      }

      .od_input {
        background-color: var(--ColorMap-AccentBaseColorBck);
        color: var(--ColorMap-BaseColorFrg1);
        padding: calc(var(--Zoom) * 6px);
        margin-top: calc(var(--Zoom) * 6px);
      }
    }

    .od_file_list {
      display: flex;
      flex-direction: column;

      .od_file_list_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--ColorMap-AccentBaseColorBck);
        color: var(--ColorMap-BaseColorFrg1);
        padding: calc(var(--Zoom) * 6px);
      }

      .od_file_list_items {
        display: flex;
        flex-direction: column;
        background-color: var(--ColorMap-BaseColorFrg1);
        border: calc(var(--Zoom) * 2px) solid var(--ColorMap-ContentFrame1);
        border-top: none;

        .od_file_list_item {
          display: flex;
          align-items: center;
          border-bottom: calc(var(--Zoom) * 1px) solid var(--ColorMap-ContentFrame1);
          color: var(--ColorMap-ContentDecorateColorFrg);
          padding: calc(var(--Zoom) * 3px);

          &:hover {
            background-color: #eee;
          }

          &:last-child {
            border-bottom: none;
          }

          .od_file_list_item_size {
            color: var(--ColorMap-ContentNormalColorFrg);
            margin-left: auto;
          }
        }
      }

      .od_file_list_button {
        background-color: var(--ColorMap-BaseColorFrg1);
        border: none;
        padding: calc(var(--Zoom) * 3px);
      }
    }

    .od_buttons {
      display: flex;
      align-self: flex-end;
      gap: calc(var(--Zoom) * 6px);
      min-height: calc(var(--Zoom) * 30px);
    }
  }
}
