.text {
  color: var(--ColorMap-ContentNormalColorFrg);

  &.text_hide {
    display: none;
  }

  &.text_bold {
    font-weight: bold;
  }

  &.text_decorated {
    color: var(--ColorMap-ContentDecorateColorFrg);
  }
}
