.file {
  background-color: var(--ColorMap-DataBrowseColorBck);
  justify-content: center;
  font-size: 18px;
  white-space: pre-wrap;
  position: relative;
  &.clickable {
    cursor: pointer;
  }
  .file_object {
    width: 100%;
    height: 100%;
  }
  p {
    word-break: break-word;
  }
  & > .img {
    display: flex;
    justify-content: center;
    align-items: center;
    .non_svg {
      align-items: center;
    }
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
  @media (max-width: 220mm) {
    font-size: 13px;
  }
}

.notSupportedFile {
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.notSupportedFile > button {
  min-width: 80px;
}
