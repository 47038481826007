@use "./../VCX/base";

.breaker {
  grid-row: auto / -1;
  @media only screen and (max-width: 980px) {
    flex: 0 1 auto;
  }
}

.ra_base {
  height: auto;
  @media only screen and (max-width: 980px) {
    justify-content: center;
    height: calc(var(--height-icon) * 1px);
  }
}
.ra_base_tile {
  grid-row: 1 / -1;
}

.ra_content {
  align-items: center;
  flex-direction: column;
  @media only screen and (max-width: 980px) {
    flex-direction: column;
  }
}

.ra_content_line {
  gap: calc(3px * var(--Zoom));
  flex-direction: row;
}

.ra_button {
  padding: calc(3px * var(--Zoom));
  width: 100%;
  background: transparent;
  color: inherit;
  border: none;
  position: relative;
  @media only screen and (max-width: 980px) {
    padding: 0px calc(3px * var(--Zoom));
  }
}

.ra_button_checked {
  background: var(--ColorMap-ContentFrame1);
  border: calc(2px * var(--Zoom)) + "px solid " + var(--ColorMap-ContentFrame3);
}

.ra_button_disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.ra_caption_font {
  @include base.label_font;
}
.ra_caption {
  color: var(--ColorMap-ContentNormalColorFrg);
  align-self: center;
  @media only screen and (max-width: 980px) {
    display: none;
  }
}

.ra_placeholder {
  display: flex;
  width: calc(16 * var(--Zoom) * 1.2px);
  height: calc(18px * var(--Zoom));
  @media only screen and (max-width: 980px) {
    width: calc(16 * var(--Zoom) * 1.5px);
    height: calc(16 * var(--Zoom) * 1.5px);
  }
}

.ra_placeholder_tile {
  width: calc(16 * var(--Zoom) * 3px);
  height: calc(28px * var(--Zoom));
  @media only screen and (max-width: 980px) {
    width: calc(16 * var(--Zoom) * 1.5px);
    height: calc(16 * var(--Zoom) * 1.5px);
  }
}
