@use "./../VCX/base";

.in_input_label {
  display: flex;
  flex: 1 1 auto;
  padding: calc(var(--InputControl-InteriorBorder) * 1px);
}

.in_label {
  display: flex;
}

.in_label_text {
  @include base.input_label_font;
  padding: 0px calc(var(--Zoom) * 8px);
}

.in_label_browse {
  background-color: inherit;
  color: var(--ColorMap-ContentDecorateColorFrg);
}

.in_label_edit {
  background-color: inherit;
  color: var(--ColorMap-ContentDecorateColorFrg);
}

.in_label_modified {
  background-color: var(--ColorMap-ContentChangeDecorateColorFrg);
  color: var(--ColorMap-ContentColorBck1);
}

.in_label_warning {
  background-color: var(--ColorMap-WarningColorBck);
  color: var(--ColorMap-WarningColorFrg);
}

.in_label_error {
  background-color: var(--ColorMap-ErrorColorBck);
  color: var(--ColorMap-ErrorColorFrg);
}

.in_label_readonly {
  background-color: inherit;
  color: var(--ColorMap-ContentDecorateColorFrg);
}

.in_label_no_highlight {
  background-color: inherit;
  color: var(--ColorMap-ContentDecorateColorFrg);
}

.in_input_browse {
  color: var(--ColorMap-DataBrowseColorFrg);
  background-color: var(--ColorMap-DataBrowseColorBck);
}

.in_input_readonly {
  color: var(--ColorMap-DataChangeROColorFrg);
  background-color: var(--ColorMap-DataChangeROColorBck);
}

.in_input_error {
  color: var(--ColorMap-DataChangeColorFrg);
  background-color: var(--ColorMap-DataChangeColorBck);
}

.in_input_warning {
  color: var(--ColorMap-DataChangeColorFrg);
  background-color: var(--ColorMap-DataChangeColorBck);
}

.in_input_modified {
  color: var(--ColorMap-DataChangeColorFrg);
  background-color: var(--ColorMap-DataChangeColorBck);
}

.in_input_edit {
  color: var(--ColorMap-DataChangeColorFrg);
  background-color: var(--ColorMap-DataChangeColorBck);
}

.in_border_browse {
  border: calc(var(--InputControl-FrameWidth) * 1px) solid var(--ColorMap-ContentFrame2);
  padding: calc(var(--InputControl-FrameWidth) * 1px);
}

.in_border_edit {
  border: 1px solid var(--ColorMap-ContentFrame3ForChange);
  padding: calc(var(--InputControl-FrameWidth) * 1px);

  &:hover {
    padding: 0px;
    border: calc(var(--InputControl-FrameWidth) * 2px) solid var(--ColorMap-ContentFrame3ForChange);
  }
}

.in_border_focused {
  border: calc(var(--InputControl-FrameWidth) * 2px) solid var(--ColorMap-ContentDecorateColorFrg);
}

.in_border_readonly {
  border: calc(var(--InputControl-FrameWidth) * 1px) solid var(--ColorMap-ContentFrame1ForChange);
}
.in_background_none {
  background-color: transparent;
}

.in_border_underline {
  border-top: inherit;
  border-left: inherit;
  border-right: inherit;
  &:hover {
    border-top: inherit;
    border-left: inherit;
    border-right: inherit;
  }
}
