@import "../utils";

.tc_vr {
  flex: 1 1 auto;
  flex-direction: column;

  .tc_vr_tabs {
    flex: 0 0 auto;
    background-color: var(--ColorMap-BaseColorBck1);
    border-bottom: getZoom(2) solid var(--ColorMap-AccentBaseColorBck);

    .tc_vr_menu {
      color: var(--ColorMap-BaseColorFrg1);
      padding: getZoom(10);
      border-right: 1px solid #00000033;
      align-items: center;
      flex: 0 0 auto;
      background-color: var(--ColorMap-BaseColorBck1);
    }
  }

  & .tc_actions button {
    padding: getZoom(10);
  }

  .tc_vr_button {
    color: var(--ColorMap-BaseColorFrg1);
    padding: getZoom(10);
    border-right: 1px solid #00000033;
    justify-content: space-between;
    flex: 0 0 auto;
    background-color: var(--ColorMap-BaseColorBck1);
    -webkit-user-select: none;
    -webkit-touch-callout: none;

    &.tc_current_page {
      background-color: var(--ColorMap-AccentBaseColorBck);
    }

    &.tc_dynamic {
      width: getZoom(150);
    }

    p {
      font-size: getZoom(13);
    }

    span {
      z-index: 1;
    }

    @media (max-width: 980px) {
      display: none;

      &.tc_always_visible {
        display: flex;
      }

      &.tc_dynamic {
        flex: 1 1 auto;
      }
    }
  }
}
