.map {
  width: 100%;
  height: 100%;
  position: relative;
  touch-action: none;
  .map_nomarker {
    display: none;
    position: absolute;
    background-color: #fff;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 18px;
    z-index: 5;
    &.active {
      display: flex;
    }
  }
  // .map_mobile_message{
  //     display:none;
  //     justify-content: center;
  //     text-align: center;
  //     align-items: center;
  //     white-space: pre-wrap;
  //     padding: 20px;
  //     z-index: 2;
  //     position: absolute;
  //     top:0;
  //     left: 0;
  //     width: 100%;
  //     height: 100%;
  //     background-color: #00000060;
  //     color: #fff;
  //     &.active{
  //         display:flex;
  //     }
  // }
  &.smap .hud .noprint {
    .print:not(.copyright),
    .compass,
    .zoom,
    .scale {
      display: none;
    }
  }
  .map_link {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
    transition: all 0.3s ease;
    transform-origin: bottom right;
    &::before,
    &::after {
      transition: all 0.3s ease;
      position: absolute;
      content: " ";
      right: 0;
      bottom: 0;
    }
    &::before {
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 0 0 25px 25px;
      border-color: transparent transparent var(--ColorMap-BaseColorBck1) transparent;
    }
    &::after {
      width: 35px;
      height: 35px;
      transform: rotate(45deg) translateX(25px);
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
      -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    }
    &:hover {
      transform: scale(1.25);
    }
    .img {
      margin: 1px;
    }
    svg {
      fill: var(--ColorMap-BaseColorFrg1);
    }
  }
}
