.tt {
  display: flex;
  flex: 1 1 auto;
  border: calc(var(--Zoom) * 1px) solid var(--ColorMap-ContentFrame1);
  background-color: var(--ColorMap-DataBrowseColorBck);
  color: var(--ColorMap-BaseColorBck1);
  padding: calc(var(--Zoom) * 6px);
  overflow: auto;

  // Použití TabToolbar v Nastavení konfigurace webu a eshopu kupřikladu
  .tb {
    flex: 1 0 auto;
    .ac_container {
      flex: 1 0 auto;
    }
  }
}
