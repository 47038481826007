@use "./../VCX/base";

:root {
  --btn_background_color: var(--ColorMap-AccentBaseColorBck);
  --btn_color: var(--ColorMap-AccentBaseColorFrg);
  --btn_style: var(--LabelControl-Font-Style);
}

.btn_base {
  flex: 1 1 auto;
  flex-direction: row;
}

.btn_VK_padding {
  padding: 1.5px;
}

.btn_padding {
  padding: 0px calc(var(--Zoom) * 6px);
}

.btn_content {
  position: relative;
  flex: 0 1 auto;
  background-color: var(--btn_background_color);
  color: var(--btn_color);
  padding: calc(var(--Zoom) * 2px);
}

.btn_content_fill {
  flex: 1 1 auto;
}

.btn_content_disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.btn_caption_center {
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
}

.btn_icon_top {
  flex: 1 1 auto;
  flex-direction: column;
}

.btn_caption_LorR {
  flex: 0 0 auto;
}

.btn_caption_stretch {
  justify-content: center;
  flex: 0 0 auto;
}

.btn_label_font {
  color: var(--btn_color);
  @include base.font(
    var(--LabelControl-Font-Weight),
    var(--btn_style),
    var(--LabelControl-Font-Name),
    calc(calc(var(--LabelControl-Font-Size) * var(--Zoom)) * 1pt),
    var(--LabelControl-Font-Decoration)
  );
}
