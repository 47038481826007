@use "./../VCX/base";

.fa_button {
  display: flex;
  background-color: var(--ColorMap-ContentFrameMin);
  border: calc(var(--InputControl-FrameWidth) * 1px) solid var(--ColorMap-ContentFrame2);
  color: var(--ColorMap-ContentNormalColorFrg);
}

.fa_edit {
  background-color: var(--ColorMap-ContentFrameMinForChange);
  border: calc(var(--InputControl-FrameWidth) * 1px) solid var(--ColorMap-ContentFrame2ForChange);
}

.fa_font {
  @include base.booktab_accessor_font;
}

.fa_img {
  flex: 0 0 auto;
  color: var(--ColorMap-ContentDecorateColorFrg);
}

.fa_label {
  margin: auto;
}
