@import "../utils";

.flc {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 1 auto;
  background-color: var(--ColorMap-DataBrowseColorBck);

  div {
    display: flex;
  }

  & .react-flow__viewport {
    [id="1__type=arrowclosed"] {
      polyline {
        stroke: black;
        fill: black;
      }
    }
  }
  & .react-flow__attribution {
    background: transparent;
  }
  &.inEdit {
    background-color: var(--ColorMap-DataChangeColorBck);

    .react-flow__edge .sourceAnchor ~ g .react-flow__edgeupdater-source {
      display: block;
      z-index: 3;
    }
    .react-flow__edge .targetAnchor ~ g .react-flow__edgeupdater-target {
      display: block;
      z-index: 3;
    }
  }

  .flc_controls {
    display: flex;
    justify-content: space-between;
    background-color: var(--ColorMap-DataBrowseColorBck);
    border-bottom: 1px solid var(--ColorMap-ContentFrame1);
    input {
      display: none;
    }

    label {
      padding: 3px;
      cursor: pointer;
      &:hover {
        background-color: #cccccc80;
      }
    }

    input:checked + label {
      border: 2px solid #bbb;
      background-color: #ccc;
      padding: 1px;
    }
    input#hideCaptions:checked + label {
      .flc_action {
        position: relative;
        &::after {
          content: " ";
          position: absolute;
          left: 90%;
          top: 30%;
          height: calc(100% + 5px);
          width: 1px;
          transform: rotate(-45deg) translate(-50%, -50%);
          background: #000;
        }
      }
    }

    .flc_action {
      height: 20px;
      width: 20px;
    }

    .flc_zoom_button {
      position: relative;
      padding: 3px 6px;
      border: none;
      background-color: transparent;
      outline: none;
      &.zoom-in {
        cursor: zoom-in;
      }
      &.zoom-out {
        cursor: zoom-out;
      }
    }
    .flc_separator {
      border: 1px solid #ccc;
      margin: 0 6px;
    }
  }

  .flc_main {
    flex: 1 1 auto;

    .react-flow__edgeupdater {
      display: none;
    }
    .react-flow {
      z-index: 0;
    }

    [id="type=arrowclosed"],
    [id="type=arrowclosed"] polyline {
      stroke: black;
      fill: black;
    }
    #arrowBack {
      stroke: silver;
      fill: silver;
    }
    #arrowActive {
      stroke: red;
      fill: red;
    }

    /* Komentář */
    .react-flow__node-Caption {
      .node-box {
        background: #fff;
        border: 1px solid #1a192b;
        border-radius: 0px;
        color: #222;
        font-size: 12px;
        padding: 2px;
        text-align: center;
        width: 80px;

        .handleEdge {
          left: 50%;
          top: 50%;
          border: 0px;
          width: 1px;
          height: 1px;
          background-color: rgba(0, 0, 0, 0);
        }
      }
    }

    /* Prvek postupu */
    .react-flow__node-Step {
      .node-box {
        border: 2px solid var(--FlowChart-Step-BorderColor);
        background: var(--FlowChart-Step-BackgroundColor);
        padding: 0px;
        width: getZoom(38);
        height: getZoom(38);

        .handleCaption {
          left: 50%;
          top: 50%;
          border: 0px;
          width: 0px;
          height: 1px;
          background-color: rgba(0, 0, 0, 0);
        }
        .handleStep {
          left: 0;
          width: 38px;
          height: 38px;
          border: 1px;
          background-color: rgba(0, 0, 0, 0);
        }
      }
      &.selected {
        .node-box {
          border-color: red;
        }
      }
    }

    /* Spoj */
    .react-flow__node-Edge {
      .node-box {
        cursor: n-resize;
        width: 1px;
        height: 1px;
        .handleEdge {
          visibility: hidden;
        }
        &.cursorDirect {
          cursor: n-resize;
        }
        &.cursorCurved {
          cursor: move;
        }
        &.cursorHorizontal {
          cursor: n-resize;
        }
        &.cursorVertical {
          cursor: w-resize;
        }
      }

      .node-box.active {
        &::after {
          content: " ";
          background-color: lime;
          width: 9px;
          height: 9px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    /* Čára / propoj */
    .react-flow__edge {
      .react-flow__edge-path {
        stroke-linecap: round;
        cursor: pointer;
        stroke-width: 2;
        &.defaultLink {
          stroke: black;
        }
        &.backLink {
          stroke: silver;
        }
        &.activeLink {
          stroke: red;
        }
      }
    }
    .react-flow__edge-attach {
      stroke: transparent;
      stroke-width: 7px;
      fill: none;
    }
    .react-flow__node-SelectingArea {
      z-index: -1;
      background-color: #00b7ff60;
      outline: 1px solid #00b7ff60;
      outline-offset: -2px;
      cursor: default;
    }
  }
}
