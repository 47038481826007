@use "./../VCX/base";
@import "../utils";

.tc_button_content {
  @include base.booktab_accessor_font;
  gap: getZoom(6);
}

.tc_button_options {
  flex: 0 0 auto;
  gap: getZoom(10);
}

.tc_expand_icon {
  display: none;

  @media (max-width: 980px) {
    display: flex;
    z-index: 1;
  }
}

.tc_column {
  flex-direction: column;

  @media (max-width: 980px) {
    flex-direction: row;
    align-items: center;
    gap: getZoom(10);
  }
}

.tc_column_reverse {
  flex-direction: column-reverse;

  @media (max-width: 980px) {
    flex-direction: row;
    align-items: center;
    gap: getZoom(10);
  }
}

.tc_row {
  flex-direction: row;
  align-items: center;
}

.tc_row_reverse {
  flex-direction: row-reverse;
  align-items: center;

  @media (max-width: 980px) {
    flex-direction: row;
  }
}
