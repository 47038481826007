.sch_chart {
  flex: 1 1 auto;
  overflow: hidden;
  position: absolute;
  height: 100%;
  width: 100%;
}

.sch_errorMessage {
  width: 100%;
  align-self: center;
  text-align: center;
}
