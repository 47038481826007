@use "./../VCX/base";

.cb {
  display: flex;
  align-items: center;
  gap: calc(var(--Zoom) * 6px);

  &:hover {
    .cb_input {
      outline: calc(var(--InputControl-FrameWidth) * 1px) solid var(--ColorMap-ContentDecorateColorFrg);
    }
  }

  &:focus {
    .cb_input {
      outline: calc(var(--InputControl-FrameWidth) * 1px) solid var(--ColorMap-ContentDecorateColorFrg);
    }

    .cb_label {
      border: calc(var(--InputControl-FrameWidth) * 1px) dotted var(--ColorMap-ContentDecorateColorFrg);
    }
  }
}

.cb_input {
  display: flex;
  flex: 0 0 auto;
  margin: calc(var(--Zoom) * 1px);
  width: calc(calc(var(--LabelControl-Height) * calc(var(--Zoom) * 1px)) + calc(var(--InputControl-FrameWidth) * 2px));
  height: calc(calc(var(--LabelControl-Height) * calc(var(--Zoom) * 1px)) + calc(var(--InputControl-FrameWidth) * 2px));
  border: calc(var(--InputControl-FrameWidth) * 1px) solid var(--ColorMap-ContentDecorateColorFrg);
  background-color: var(--ColorMap-DataBrowseColorBck);

  &.cb_input_edit {
    background-color: var(--ColorMap-DataChangeColorBck);
  }
}

.cb_label {
  border: calc(var(--InputControl-FrameWidth) * 1px) dotted transparent;
}

.cb_font {
  @include base.label_font;
}
