.scroll_button {
  display: flex;
  flex: 0 0 auto;
  width: calc(var(--Zoom) * 30px);
  background-color: var(--ColorMap-BaseColorBck1);
  color: #fff;
  border: none;

  &.scroll_button_vertical {
    width: auto;
    height: calc(var(--Zoom) * 30px);
  }
}

.scroll_content {
  display: flex;
  flex: 1 1 auto;
  scrollbar-width: none;

  &.scroll_content_vertical {
    flex-direction: column;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}
