.wce {
  display: flex;
  flex-direction: column;

  .wce_iframe {
    resize: vertical;
  }
}

.wce_iframe {
  border: none;
  background-color: var(--ColorMap-DataBrowseColorBck);

  &.edit {
    background-color: var(--ColorMap-DataChangeColorBck);
  }
}
