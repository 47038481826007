@use "./../VCX/base";

.mm_backdrop {
  @include base.label_font;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
  inset: 0;
  animation: darken 0.3s forwards;
}

.mm {
  position: fixed;
  height: 100%;
  width: clamp(calc(var(--Zoom) * 300px), 22vw, calc(var(--Zoom) * 400px));
  background: var(--ColorMap-DataBrowseColorBck);
  box-shadow: 0px 0px 10px rgb(0 0 0 / 30%);
  flex-direction: column;
  padding: calc(var(--Zoom) * 15px);
  gap: calc(var(--Zoom) * 20px);
  animation: slide-right 0.3s;

  & > * {
    flex: 0 0 auto;
  }

  .mm_controls {
    justify-content: space-between;
  }
  .mm_input {
    color: var(--ColorMap-ContentNormalColorFrg);
  }

  .mm_title {
    font-weight: bold;
    font-size: 1.3em;
    color: var(--ColorMap-ContentNormalColorFrg);
  }

  .mm_input_wrapper {
    display: flex;
    padding: calc(var(--Zoom) * 5px);
    border: 1px solid var(--ColorMap-ContentFrame1);

    .mm_input {
      @include base.label_font;
      border: none;
      flex: 1 1 auto;
      background-color: var(--ColorMap-DataBrowseColorBck);
    }
  }

  .mm_list {
    overflow: hidden;
  }

  > .mm_list {
    flex: 1 1 auto;
    overflow: auto;
  }

  .mm_item {
    display: flex;
    flex-direction: column;
    list-style-type: none;
  }

  .mm_item .mm_list {
    padding-left: calc(var(--Zoom) * 15px);

    &.mm_expand {
      animation: expand 0.3s;
    }

    &.mm_collapse {
      animation: collapse 0.3s forwards;
    }
  }

  .mm_button {
    @include base.label_font;
    margin: calc(var(--Zoom) * 3px);
    padding: calc(var(--Zoom) * 3px);
    display: flex;
    align-items: center;
    gap: calc(var(--Zoom) * 3px);
    cursor: pointer;
    flex: 0 0 auto;
    border: none;
    background-color: var(--ColorMap-DataBrowseColorBck);
    transition: all 0.3s;
    color: var(--ColorMap-ContentNormalColorFrg);

    &:hover,
    &:focus {
      box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 15%);
      background-color: var(--ColorMap-DataBrowseColorBck);
      transition: none;
    }

    &:active {
      background-color: rgb(0 0 0 / 15%);
    }

    &::after {
      background-color: unset !important;
    }

    &.mm_menu_icon {
      color: var(--ColorMap-ContentDecorateColorFrg);
    }
  }
}

// Animations

@keyframes expand {
  0% {
    height: 0px;
    opacity: 0;
  }
  100% {
    height: var(--height);
    opacity: 1;
  }
}

@keyframes collapse {
  0% {
    height: var(--height);
    opacity: 1;
  }
  100% {
    height: 0px;
    opacity: 0;
  }
}

@keyframes darken {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: rgb(0 0 0 / 30%);
  }
}

@keyframes slide-right {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

// Media query

@media (max-width: 980px) {
  .mm {
    width: 100%;
  }
}
