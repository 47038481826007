.tb {
  display: flex;
  flex: 0 0 auto;
  gap: calc(var(--Zoom) * 3px);
  padding: 0px calc(var(--Zoom) * 3px);

  .tb_mobile_hide_actions {
    display: flex;

    @media (max-width: 980px) {
      display: none;
    }
  }
}
