.cp {
  flex-direction: column;
  align-items: center;
  background: #fff;
  width: 100%;
  height: 100%;
  .cp_buttons {
    width: 100%;
    max-width: 300px;
    .button {
      min-height: 23px;
    }
    & > * {
      width: 50% !important;
      padding: 3px;
    }
  }
  .cp_editor,
  .cp_editor div {
    display: block;
  }
  .cp_editor {
    width: 290px !important;
    padding: 5px;
    box-shadow: inherit !important;
  }
}

.modal_mobile {
  .cp {
    .cp_editor {
      width: 100% !important;
      max-width: 600px;
    }
  }
}
