@use "./../VCX/base";

.calendar {
  @include base.label_font;
  position: relative;
}

.fc .fc-daygrid-day-frame,
.fc .fc-daygrid-body-balanced .fc-daygrid-day-events,
.fc .fc-daygrid-event-harness,
.fc .fc-daygrid-body-natural .fc-daygrid-day-events,
.fc .fc-more-popover,
.fc .fc-more-popover .fc-popover-body,
.fc-event .fc-event-main,
.fc-time-and-event,
.fc .fc-scroller-liquid-absolute {
  display: block;
}

.fc .fc-toolbar.fc-header-toolbar {
  background-color: var(--ColorMap-AccentBaseColorBck);
  color: var(--ColorMap-BaseColorFrg1);
}

.fc .fc-button-group > .fc-button {
  background-color: var(--ColorMap-AccentBaseColorBck);
  border-color: var(--ColorMap-AccentBaseColorBck);
}

.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  background-color: var(--ColorMap-ContentChangeDecorateColorFrg);
  border-color: var(--ColorMap-ContentChangeDecorateColorFrg);
}

.fc .fc-button-primary:not(:disabled):active:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:focus {
  box-shadow: 0 0 0 0 transparent;
}

table.fc-scrollgrid-liquid > thead {
  background-color: var(--ColorMap-ContentColorBck1);
}

table.fc-scrollgrid-liquid > tbody {
  background-color: var(--ColorMap-BaseColorFrg1);
}

.fc .fc-col-header-cell-cushion {
  font-weight: normal;
  color: var(--ColorMap-BaseColorBck1);
}

.fc .fc-daygrid-day-number {
  color: #000;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0;
}

.fc .fc-daygrid-day.fc-day-today,
.fc .fc-timegrid-col.fc-day-today {
  background-color: var(--ColorMap-GridRulerColorBck);
}

.fc-day-today .fc-daygrid-day-number {
  font-weight: bold;
}

.fc .fc-daygrid-event {
  padding: 2px 4px;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end ul {
  list-style-position: inside;
}

.fc-event-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.fc-event-info {
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.fc-event-info-not-all-day {
  display: flex;
  align-items: center;
}

.fc-event-name {
  font-weight: bold;
}

.fc-circle {
  border: 1px solid var(--ColorMap-AccentBaseColorBck);
  background-color: var(--ColorMap-AccentBaseColorBck);
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-right: 5px;
  flex: 0 0 auto;
}

@media only screen and (max-width: 980px) {
  .fc-circle,
  .fc-event-time {
    display: none !important;
  }
}

.fc .fc-toolbar-title,
.fc .fc-col-header-cell-cushion {
  text-transform: capitalize;
}

.fc .fc-button {
  overflow: hidden;
}

.fc-button-delete-wrapper {
  flex: 0 0 auto;
  display: flex;
}

.fc-button-delete-event {
  border: none;
  background-color: inherit;
  color: inherit;
}

.fc-v-event .fc-event-time {
  display: block;
}

.fc-timegrid-cols {
  z-index: 2;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end {
  color: unset;
}

.fc-state-icon {
  margin-right: 3px;
  align-items: center;
}

.fc-event-main {
  overflow: hidden;
}

.fc-timegrid-event .fc-event-name {
  white-space: normal;
}

button:hover::after,
button:focus-visible::after,
button:active::after {
  content: "";
  position: absolute;
  inset: 0;
  display: block;
}

@media (hover: hover) {
  button:hover::after {
    background-color: rgba(0, 0, 0, 0.17);
  }
}

button:focus-visible::after {
  background-color: rgba(0, 84, 166, 0.3);
}

button:active::after {
  background-color: rgba(0, 0, 0, 0.5);
}

button[disabled]:hover::after {
  background-color: transparent;
}
