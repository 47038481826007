.pdf {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  .pdf_wrapper {
    justify-content: center;
    align-self: center;
    width: 100%;
    height: 100%;
  }
  .pdf_control {
    justify-content: center;
    align-items: center;
    padding: 2px;
    .pdf_cp_last,
    .pdf_cp_first,
    .pdf_cp_back,
    .pdf_cp_forward {
      background-color: var(--Color-Btn-Background);
      border: 1px solid var(--Color-Btn-Border);
      border-radius: 3px;
      color: var(--Color-Btn-Color);
      padding: 3px;
      margin: 2px;
      cursor: pointer;
      &:hover {
        background-color: var(--Color-Btn-Background-Hover);
      }
      @media (max-width: 980px) {
        padding: 5px;
        svg,
        .img {
          width: 27px !important;
          height: 27px !important;
        }
      }
    }
    .pdf_cp_page {
      padding: 5px;
      font-size: 14px;
      width: 70px;
      text-align: center;
      justify-content: center;
      @media (max-width: 980px) {
        font-size: 16px;
      }
    }
  }
  .pdf_download {
    position: absolute;
    right: 5px;
    bottom: 5px;
    padding: 5px;
    opacity: 0.8;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    cursor: pointer;
    justify-content: center;
    svg {
      width: 17px;
      height: 17px;
      fill: #fff;
    }
    @media (max-width: 980px) {
      font-size: 20px;
      min-width: 40px;
      svg {
        width: 27px;
        height: 27px;
        fill: #fff;
      }
    }
  }
  .react-pdf__Page__canvas {
    width: auto !important;
    height: auto !important;
    max-width: var(--pdf-maxWidth) !important;
    max-height: var(--pdf-maxHeight) !important;
  }
  .react-pdf__Page__textContent,
  .react-pdf__Page__annotations {
    display: none;
  }
}
