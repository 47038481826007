.cr {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  background-color: var(--ColorMap-ContentFrame1);

  .cr_qr {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    gap: calc(var(--Zoom) * 16px);

    .cr_qr_torch {
      padding: calc(var(--Zoom) * 16px);
      align-self: center;
      border: none;

      &.active {
        background-color: var(--ColorMap-AccentBaseColorBck);
      }
    }
  }

  .cr_buttons {
    display: flex;
    flex: 0 0 auto;
  }
}
