@use "./../VCX/base";

.pp {
  @include base.title_font;
  display: flex;
  flex-direction: column;
  background-color: var(--ColorMap-BaseColorBck1);
  padding: 0px calc(var(--MarginX) * 1px) calc(var(--MarginY) * 1px);
  gap: calc(var(--MarginY) * 2px);

  .pp_title {
    padding: 2px;
    color: var(--ColorMap-BaseColorFrg1);
  }
}
