.sp {
  display: flex;
  gap: calc(var(--MarginX) * 1px);
  background-color: transparent;

  &.sp_in_preview {
    background-color: var(--ColorMap-DataBrowseColorBck);
  }

  &.sp_va_start {
    align-items: flex-start;
  }

  &.sp_va_end {
    align-items: flex-end;
  }

  &.sp_va_center {
    align-items: center;
  }
}
