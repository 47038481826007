@use "./../VCX/base";

.lw {
  @include base.list_view_font;
  color: var(--ColorMap-DataBrowseColorFrg);
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: calc(var(--Zoom) * 6px);

  .lw_group {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    gap: calc(var(--Zoom) * 6px);

    .lw_title {
      position: relative;
      display: flex;
      align-items: center;
      gap: calc(var(--Zoom) * 6px);
      padding-left: calc(var(--Zoom) * 6px);

      &::after {
        content: "";
        border-top: 1px solid var(--ColorMap-ContentFrame3);
        flex: 1 1 auto;
      }
    }

    .lw_buttons {
      display: flex;
      flex-wrap: wrap;

      .lw_button {
        display: flex;
        flex-direction: column;
        width: calc(var(--Zoom) * 100px);
        align-items: center;
        text-align: center;

        &.lw_selected {
          background-color: var(--ColorMap-ContentChangeDecorateColorFrg);
        }

        &:hover {
          background-color: var(--ColorMap-AccentBaseColorBck);
        }

        .lw_caption {
          white-space: normal;
          word-break: break-word;
        }
      }
    }
  }
}
