@use "./../VCX/base";

.dl {
  @include base.label_font;
  display: flex;
  gap: calc(var(--Zoom) * 3px);

  &.dl_column {
    flex-direction: column;
    gap: 0px;
  }

  &.dl_bottom_line {
    border-bottom: 1px solid var(--ColorMap-ContentFrameMin);
  }
}

.dl_title {
  & > p {
    text-overflow: clip;
  }
}

.dl_value {
  display: flex;
  flex: 1;
  gap: calc(var(--Zoom) * 3px);

  .dl_prefix {
  }

  .dl_text {
    display: flex;
    flex: 1 1 auto;

    &.dl_text_link {
      cursor: pointer;
    }
  }

  .dl_suffix {
  }
}

.dl_va_top {
  align-items: flex-start;
}

.dl_va_center {
  align-items: center;
}

.dl_va_bottom {
  align-items: flex-end;
}

.dl_ha_left {
  justify-content: flex-start;
  text-align: left;
}

.dl_ha_center {
  justify-content: center;
  text-align: center;
}

.dl_ha_right {
  justify-content: flex-end;
  text-align: right;
}
