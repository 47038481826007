.wc {
  position: relative;

  &.hidden {
    visibility: hidden;
  }

  .wc_video {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
