body {
  font-family: "Arial", sans-serif;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}

@-webkit-keyframes bckg {
  0% {
    background-color: #333;
  }
  100% {
    background: #fff;
  }
}

@keyframes bckg {
  0% {
    background-color: #333;
  }
  100% {
    background: #fff;
  }
}

@-webkit-keyframes intro_blur {
  from {
    z-index: 1;
    background: rgba(0, 0, 0, 0.8);
  }
  to {
    z-index: 1;
    background: rgba(0, 0, 0, 0);
  }
}

@keyframes intro_blur {
  from {
    z-index: 1;
    background: rgba(0, 0, 0, 0.8);
  }
  to {
    z-index: 1;
    background: rgba(0, 0, 0, 0);
  }
}

@-webkit-keyframes intro_card {
  from {
    -webkit-transform: rotateY(90deg);
    transform: rotateY(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0deg) scale(1);
    transform: rotateY(0deg) scale(1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}

@keyframes intro_card {
  from {
    -webkit-transform: rotateY(90deg);
    transform: rotateY(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0deg) scale(1);
    transform: rotateY(0deg) scale(1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-3px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(3px, 0, 0);
  }
}

@-webkit-keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(2.2);
    transform: scale(2.2);
    opacity: 0;
  }
}
@keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(2.2);
    transform: scale(2.2);
    opacity: 0;
  }
}

.animation-helper {
  z-index: -1; /*prevent overflow over page-scroller*/
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  -webkit-animation: intro_blur ease-in-out 1s;
  animation: intro_blur ease-in-out 1s;
}

.bckg {
  background-image: url("/wwwroot/img/login_background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.login-container {
  width: 100vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.animation-container {
  -webkit-animation: intro_card ease-in-out 1s;
  animation: intro_card ease-in-out 1s;
}

.animation-ping-container {
  width: 40%;
  margin: 0 auto;
  flex-direction: row;
}

.animation-ping-item {
  flex: 1;
}

.animation-ping-delay0,
.animation-ping-delay1,
.animation-ping-delay2,
.animation-ping-delay3,
.animation-ping-delay4 {
  width: 20px;
  height: 20px;
  background-color: #00539b;
  border-radius: 35%;
  margin: 0 auto;
}

.animation-ping-delay0 {
  -webkit-animation: ping 1s ease-in-out infinite both;
  animation: ping 1s ease-in-out infinite both;
  -webkit-animation-delay: 0;
  animation-delay: 0;
}
.animation-ping-delay1 {
  -webkit-animation: ping 1s ease-in-out infinite both;
  animation: ping 1s ease-in-out infinite both;
  -webkit-animation-delay: 200ms;
  animation-delay: 200ms;
}
.animation-ping-delay2 {
  -webkit-animation: ping 1s ease-in-out infinite both;
  animation: ping 1s ease-in-out infinite both;
  -webkit-animation-delay: 400ms;
  animation-delay: 400ms;
}
.animation-ping-delay3 {
  -webkit-animation: ping 1s ease-in-out infinite both;
  animation: ping 1s ease-in-out infinite both;
  -webkit-animation-delay: 600ms;
  animation-delay: 600ms;
}
.animation-ping-delay4 {
  -webkit-animation: ping 1s ease-in-out infinite both;
  animation: ping 1s ease-in-out infinite both;
  -webkit-animation-delay: 800ms;
  animation-delay: 800ms;
}

.logo-container {
  width: 100%;
  flex-shrink: 0;
  justify-content: end;
  background: #f4f4f4;
  video {
    width: 100%;
  }
}

.main-logo {
  background-color: #fff;
  width: 100%;
}

.two-row {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}

.one-row {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.login-form {
  -webkit-box-shadow: 0px 3px 21px rgba(0, 0, 0, 0.8);
  box-shadow: 0px 3px 21px rgba(0, 0, 0, 0.8);
  width: 400px;
  min-height: 486px;
  flex-direction: column;
  -ms-flex-direction: column;
  button {
    cursor: pointer;
  }
}

.login-form form {
  padding: 30px;
}

.login-content {
  background: rgba(255, 255, 255, 0.92);
  height: 100%;
}

.loginBusyIndicatorFullBorder {
  .bi .bi_wrap .bi_loader .bi_loading {
    color: #0054a6;
    border-color: #0054a6;
  }
}

.login-info {
  width: 100%;
  height: 100%;
  flex-direction: column;
  -ms-flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5%;
  position: relative;
}

.login-info .text {
  margin-top: 50%;
}

.login-info .imgInfo {
  height: 36px;
  width: 36px;
  margin: 5px;
}
.login-info {
  .bi_title {
    padding-top: 10px;
  }
  .bi .bi_wrap .bi_loader {
    min-height: 100px;
    min-width: 100px;
    .bi_loading {
      border-width: 4px;
    }
  }
}

.login-err {
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.login-err span {
  word-wrap: break-word;
  flex: 6;
}

.login-err .imgErr {
  height: 48px;
  flex: 1;
  margin-right: 12px;
}

.login-reconnect {
  flex-direction: column;
  flex: 1;
  width: 100%;
  align-items: center;
}

.login-reconnect span {
  word-wrap: break-word;
  flex: 1;
  text-align: center;
}

.login-reconnect .svg {
  flex: 4;
  margin: 36px;
}

.input-field {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.input-field label {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  color: #00539b;
  line-height: 20px;
  font-size: 12px;
  letter-spacing: 0px;
  height: 19px;
  padding: 2px;
  padding-right: 7px;
  padding-left: 4px;
}

.input-field input {
  padding: 3px;
  font-size: 16px;
  border: 1px solid #00539b;
}

::-ms-reveal {
  display: none;
}

.password-wrapper {
  position: relative;
}

.input-password {
  padding-right: 30px;
  width: 100%;
}

.reveal-password-btn {
  position: absolute !important;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border: none;
  height: 20px;
}

.reveal-password-btn svg {
  height: 20px;
  fill: #00539b;
}

.shakeOn {
  input {
    animation: shake 0.7s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
    background-color: #00539b40; //orpacity
  }
  @media (max-width: 220mm) {
    label span {
      display: block;
      animation: shake 0.7s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
    }
  }
}

.input-field input {
  transition: background-color 0.4s linear;
}

.error_message {
  font-size: 90%;
  text-align: justify;
  border: 2px #004291 solid;
  padding: 5px 2px;
  margin-bottom: 15px;
}

#notConnectMessage {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: -4px;
  padding: 30px;
  background: rgba(255, 255, 255, 0.92);
}

.one-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.one-column h2 {
  font-size: 1em;
  color: #00539b;
}

.two-column {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.info {
  flex-basis: 100%;
  display: inline-block;
  font-size: 11px;
}

.loginBtn {
  width: 70%;
  height: 32px;
  background-color: #084f91;
  border: none;
  color: #fff;
}

.typeSwitchBtn {
  width: 32px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 32px;
  background-color: #084f91;
  border: none;
  color: #fff;
  cursor: pointer;
}

.typeSwitchBtn::before {
  content: " ";
  position: absolute;
  bottom: 2px;
  right: 2px;
  border-style: solid;
  border-width: 0 0 5px 5px;
  border-color: transparent transparent #ffffff transparent;
  transform: rotate(0deg);
}

.reconnectBtn {
  background-color: gray;
  margin-top: 12px;
}

.reconnectBtn:focus,
.reconnectBtn:active {
  background-color: rgb(70, 69, 69);
}

.reconnectBtn:hover {
  background-color: rgb(70, 69, 69);
}

.loginBtn-container {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}

.logo-footer {
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  text-align: right;
  a {
    width: 100%;
  }
}

.logo-footer img {
  width: 100%;
}

.cleaner {
  display: block;
  width: 100%;
  height: 0;
  clear: both;
}

.height10 {
  height: 10px;
}

.height20 {
  height: 20px;
}

.height30 {
  height: 30px;
}

.remember-password {
  position: relative;
}

#remember-password_input {
  opacity: 0;
  position: absolute;
}

.remember-password_label {
  display: flex;
  gap: 5px;
  font-size: 12px;
  align-items: center;
  color: #00539b;
}

.remember-password .checkbox {
  border: 1px solid #00539b;
  display: block;
  height: 16px;
  width: 16px;
  position: relative;
}

.remember-password .checkbox:after {
  content: "";
  position: absolute;
  left: 4px;
  top: 0px;
  width: 4px;
  height: 9px;
  border: 2px solid #00539b;
  border-top: 0;
  border-left: 0;
  opacity: 0;
  /* transform: rotate(45deg); */
  transition: opacity 0.2s;
  animation: uncheck 0.2s;
}

input:checked + label > .checkbox:after {
  opacity: 1;
  animation: check 0.2s forwards;
}

@keyframes check {
  0% {
    transform: rotate(45deg) scale(0);
  }
  75% {
    transform: rotate(45deg) scale(1.25);
  }
  100% {
    transform: rotate(45deg) scale(1);
  }
}

@keyframes uncheck {
  0% {
    transform: rotate(45deg) scale(0.75);
  }
  /* 25% {
    transform: rotate(45deg) scale(1.25);
  } */
  100% {
    transform: rotate(45deg) scale(1.25);
  }
}

.maintenance {
  justify-content: center;
  align-items: center;
  width: 100%;
}

.box {
  background: white;
  height: 20rem;
  width: 40rem;
  border-radius: 5px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 30px 0px rgb(0 0 0 / 50%);
}

.box a,
h2 {
  margin-bottom: 2rem;
}

.box p {
  line-height: 1.5rem;
}

.box .indent {
  margin-top: 1rem;
}

.inputs {
  flex-direction: column;
  gap: 15px;
}

.no-shrink {
  flex: 0 0 auto;
}

.error-message {
  flex-direction: column;
  gap: 20px;
}

.select-session-title {
  margin-bottom: 13px;
  width: 100%;
  justify-content: center;
  color: #084f91;
  font: normal normal normal 20px/27px "Segoe UI", "Arial", "sans-serif";
}

.select-session-container {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  flex-direction: column;
}

.select-session-box-wrap {
  flex: 0 0 auto;
  margin-bottom: 12px;
  position: relative;
  .session-slide {
    position: absolute;
    height: 100%;
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin: 0 1px;

    .session-btn-connect,
    .session-btn-cancel {
      background-image: inherit;
      background-color: white;
      width: 25px;
      height: 25px;
      transition: all 0.2s ease;
    }
    & .session-btn-connect {
      -webkit-mask-image: url(/wwwroot/img/link_connect.svg);
      mask-image: url(/wwwroot/img/link_connect.svg);
      margin-right: 5px;
    }
    & .session-btn-cancel {
      -webkit-mask-image: url(/wwwroot/img/link_cancel.svg);
      mask-image: url(/wwwroot/img/link_cancel.svg);
      margin-left: 5px;
    }

    &.session-slide-connect {
      left: 0;
      background-color: #084f91;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &.session-slide-cancel {
      right: 0;
      background-color: #c60000;
      color: #fff;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  .select-session-box {
    z-index: 1;
    &.transition {
      transition: all 0.4s ease;
    }
    &.done {
      & ~ .session-slide {
        -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
        box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
      }
    }
  }
}

.select-session-box {
  flex: 1 1 auto;
  width: 100%;
  min-height: 73px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border: 1px solid #cfcfcf;
  background: #ffffff;
  font: normal normal 600 13px/17px "Segoe UI", "Arial", "sans-serif";
}

.session-state {
  width: 6px;
  flex: 0 1 auto;
  margin: 1px;
}

.session-info-box {
  flex-direction: column;
  margin: 6px;
  width: 100%;
}

.session-info {
  height: 17px;
  margin: 6px;
}

.session-info-txt {
  flex: 1;
  margin-left: 8px;
}

.session-info-txt-main {
  color: #084f91;
}

.session-btns {
  flex: 0 0 37px;
  border-left: 1px solid #cfcfcf;
  flex-direction: column;
}

.session-btn {
  height: 21px;
  width: 21px;
  flex: 1 0 auto;
  margin: auto;
  align-items: center;
  background: inherit;
  border: 0;
}

.session-btn-cancel {
  background-image: url(/wwwroot/img/link_cancel.svg);
}

.session-btn-connect {
  background-image: url(/wwwroot/img/link_connect.svg);
}

.session-loginBtn {
  background-color: #084f91;
  width: 133px;
}

.session-loginBtn:hover {
  background-color: #073e72;
}

.session-msg-center {
  height: 100%;
}

.session-msg-center > p {
  align-self: center;
  width: 100%;
  text-align: center;
  color: #084f91;
}

.login-bottom-wrap {
  flex-direction: column;
}

.login-content > form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-input-wrap {
  opacity: 0;
  height: 0;
  overflow: hidden;
  position: fixed;
}
.card-info-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.card-info-wrap,
.card-info-text,
.card-info-svg {
  padding: 10px 0;
}
.card-info-text {
  color: #084f91;
  text-align: center;
}

.pin-login-wrap {
  max-width: 230px;
  width: 100%;
  margin: auto;
}

.pin-login-wrap {
  width: 100%;
  flex-direction: column;
}
.pin-button-wrap {
  padding-top: 16px;
  padding-bottom: 6px;
  margin: -8px;
  display: flex;
  flex-wrap: wrap;
}
.pin-button-wrap > * {
  padding: 8px;
}
.pin-button-item {
  width: 33.33333%;
}
.pin-button-item button {
  height: 30px;
  padding: 3px;
  background-color: #084f91;
  border: none;
  color: #fff;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.pin-button-item button svg {
  max-width: 100%;
  max-height: 100%;
}

.select-session-container {
  padding: 10px;
  height: 380px;
}

@media (max-width: 220mm) {
  .pin-button-wrap {
    padding-top: 20px;
    margin: -3%;
  }
  .session-info-txt p {
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
  }
  .pin-button-wrap > * {
    padding: 3%;
  }
  .inputs {
    gap: 20px;
  }
  .typeSwitchBtn {
    margin-left: 20px;
  }
  .login-content {
    background: rgba(252, 252, 252, 0.9);
    & > form {
      padding: 10%;
    }
  }
  .login-form {
    width: 500px;
    min-height: 500px;

    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16);
    position: relative;
  }

  .input-field {
    position: relative;
    label {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      z-index: 1;
      font-size: 13px;
      line-height: inherit;
    }
    input {
      height: 48px;
      padding-left: 70px;
      font-size: 13px;
      border: 1px solid #00539b;
      border-radius: 10px;
      font-weight: 600;
      &#k2_pin {
        padding-left: 50px;
        font-size: 20px;
        letter-spacing: 2px;
      }
    }
    .reveal-password-btn {
      height: 30px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .select-session-box {
    border-radius: 10px;
  }
  .select-session-box-wrap {
    margin-bottom: 20px;
    &:last-child .select-session-box {
      margin-bottom: 0;
    }
  }
  .session-state {
    border-radius: 3px;
    margin: 10px 4px;
  }

  .logo-footer {
    -ms-flex-preferred-size: 20%;
    flex-basis: 20%;
  }
  .login-bottom-wrap {
    .loginBtn-container {
      display: none;
    }
  }
  .loginBtn {
    height: 48px;
    width: 100%;
    border-radius: 10px;
    text-transform: uppercase;
    font-weight: 600;
  }
  .loginBtn-container {
    padding: 20px 0;
    .loginBtn {
      width: inherit;
      flex: 1 1 auto;
    }

    .typeSwitchBtn {
      height: 48px;
      width: 48px;
      border-radius: 10px;
      &::before {
        right: 7px;
        bottom: 7px;
      }
      svg {
        font-size: 30px;
        max-height: 20px;
        width: 1em;
        height: 1em;
      }
    }
  }

  .remember-password_label {
    gap: 10px;
  }
  .remember-password .checkbox {
    width: 25px;
    height: 25px;
    background-color: #fff;
    border-radius: 10px;
    &::after {
      left: 9px;
      top: 5px;
    }
  }

  // PIN
  .pin-login-wrap {
    max-width: inherit;
    display: block;
    .pin-button-item {
      button {
        height: 48px;
        border-radius: 10px;
        font-size: 22px;
        svg {
          width: 25px;
          height: 20px;
        }
      }
    }
  }

  .login-bottom-wrap {
    background-color: #fff;
    padding: 10%;
    visibility: hidden;
    z-index: 2;
    opacity: 0;
    display: flex;
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    font-size: 11px;
    &.active {
      visibility: visible;
      opacity: 1;
      bottom: 0%;
    }
  }
  .mobile-bottom-opener {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #1db2ef;
    text-decoration: underline;
    span {
      cursor: pointer;
    }
  }
  .mobile-bottom-closer {
    background-color: #084f91;
    color: #fff;
    width: 100%;
    border-radius: 10px;
    font-size: 13px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    cursor: pointer;
    margin-top: auto;
  }
  .select-loginBtn-wrap {
    padding: 10px;
    width: 100%;
  }
  .info {
    font-size: 12px;
  }
  .login-info {
    padding: 10%;
    &.selectSession {
      padding-right: 0;
      padding-left: 0;
    }
    .select-session-container {
      overflow-x: hidden;
      padding: 10px 10%;
    }
    .select-loginBtn-wrap {
      padding: 0 10%;
    }
  }
}

@media (max-height: 600px) {
  .login-content {
    & > form {
      padding: 5% 10%;
    }
  }
  .pin-button-wrap {
    margin: -5px;
  }
  .pin-button-wrap > * {
    padding: 5px;
  }
}
@media (max-height: 560px) {
  .pin-login-wrap .pin-button-item button {
    height: 40px;
  }
}
@media (max-height: 530px) {
  .pin-login-wrap .pin-button-item button {
    height: 35px;
  }
}

@media (max-width: 150mm) {
  body {
    overflow-y: auto;
    margin: 0;
  }

  .card-info-wrap {
    padding-top: 35px;
  }

  .main-logo {
    margin-top: 5vh;
    width: 80%;
  }

  .login-form {
    width: 100vw;
    height: 100vh;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .select-session-title {
    margin-bottom: 5%;
  }
  .select-session-container {
    flex: 1 1 auto;
    height: inherit;
  }

  .login-info {
    padding: 10%;
    &.selectSession {
      padding-right: 0;
      padding-left: 0;
    }
    .select-session-container {
      padding: 10px 10%;
    }
    .select-loginBtn-wrap {
      padding: 10px 10%;
    }
  }

  .bckg {
    background: transparent;
  }
}

.block {
  display: block;
}

input[type="checkbox"] {
  &:focus {
    & + .remember-password_label .checkbox {
      outline: calc(var(--InputControl-FrameWidth) * 1px) solid var(--ColorMap-ContentDecorateColorFrg);
    }
  }
}
