.modal_menu_backdrop {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.modal_menu {
  box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.3);
  border: 1px solid #ababab;
}
