@use "./../VCX/base";

.fi {
  display: flex;
  flex-direction: column;
}

.fi_html {
  display: flex;
  flex: 1 1 auto;
  background-color: var(--ColorMap-DataBrowseColorBck);
  white-space: pre-wrap;
  position: relative;
  flex-direction: row-reverse;

  & iframe {
    position: absolute;
    height: 100%;
    width: 100%;
  }
}

.fi_textarea {
  @include base.input_edit_font;
  background: transparent;
  border: none;
  width: 100%;
  padding-left: calc(var(--Zoom) * 3px);
  min-width: calc(var(--Zoom) * 20px);
  flex: 1;
  user-select: auto;
}

.fi_label {
  display: flex;
  flex-direction: column;
}

.fi_toolbar {
  display: flex;
  justify-content: space-between;
  background-color: var(--ColorMap-ContentColorBck1);
  height: calc(var(--ExpanderControl-HFHeight) * 1px);
  color: var(--ColorMap-BaseColorBck1);
  border: 1px solid var(--ColorMap-ContentFrame1);
}

.fi_btn_switch {
  border: none;
  background-color: transparent;
  min-width: min-content;
  align-self: flex-end;
  margin: 4px;
  position: absolute !important;
}
